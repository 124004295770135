<div id='content' xmlns="http://www.w3.org/1999/html">
  <img src="assets/images/logo.svg" alt="logo" width="90" style="padding: 25px">
  <p *ngIf='!unitId' style="font-weight: bold">Invalid Unit ID.</p>
  <p *ngIf='!location' style="font-weight: bold">You need to allow access to your location for this site in order to send an update.</p>
  <p *ngIf='!location' style="color: #cccccc">
    Guide for problems with allowing access to your location:
    <br><br>
    If you are an Android user navigate to the following: Settings → Privacy → Permission manager → Location → Chrome →
    Check one of the two options ‘Allow only while using the app” or 'Ask every time’.
    <br><br>
    If you are an iPhone user navigate to the following: Settings → Privacy & Security → Location Services → Safari
    Websites, then check one of the two options ‘Ask Next Time Or When I Share' or ‘While Using the App’.
    <br><br>
    After this try scanning the QR code again or refreshing this website and click allow when prompted.</p>
  <div style="height: 150px"></div>
  <div *ngIf='submissionSuccessful || submissionError'>
    <p *ngIf="submissionSuccessful">Successfully created unit action event with id: {{submissionSuccessful}}</p>
    <p *ngIf="submissionError">Error while submitting unit location: {{submissionError}}</p>
  </div>
</div>
