import {Component, OnInit} from '@angular/core';
import {ActionEventDto, GpsLocation} from './dto/action-event.dto';
import {ActivatedRoute} from '@angular/router';
import {ActionEventService} from '../action-event.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  unitId: string | null = null
  location: GpsLocation | null = null
  submissionSuccessful: string | null = null
  submissionError: string | null = null

  constructor(private route: ActivatedRoute, private _actionEventService: ActionEventService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.unitId = params.get('unitId');
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          if (position && this.unitId) {
            this.location = <GpsLocation>{
              latitude: position.coords.latitude.toString(),
              longitude: position.coords.longitude.toString(),
            }

            this.submit()
          }
        }, null, {enableHighAccuracy: true, maximumAge: 0})
      } else {
        alert('The browser you are using does not support geolocation')
      }
    })
  }

  submit() {
    if (this.location && this.unitId) {
      const actionEventDto = <ActionEventDto>{
        unitId: this.unitId,
        location: <GpsLocation>{
          latitude: this.location.latitude,
          longitude: this.location.longitude,
        },
      }
      this._actionEventService.createUnverifiedActionEvent(actionEventDto).subscribe(
        next => {
          this.submissionSuccessful = next
        }, error => {
          this.submissionError = error.message
        },
      )
    }
  }

}
