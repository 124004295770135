import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActionEventDto} from './home/dto/action-event.dto';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ActionEventService {

  baseUrl = environment.apiUrl;

  constructor(
    private _httpClient: HttpClient,
  ) {
  }

  createUnverifiedActionEvent = (actionEvent: ActionEventDto): Observable<string> => this._httpClient.post(`${this.baseUrl}/units/unverified-action-event`, actionEvent, {responseType: 'text'});
}
